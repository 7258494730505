import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import ExcelExportButton from "./ExcelExportButton";
import TableContainer from "@mui/material/TableContainer";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import Chip from "@mui/material/Chip";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useTheme } from "@mui/material/styles";
import * as XLSX from "xlsx";
import {
  Avatar,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { Button, Link } from "../../node_modules/@mui/material/index";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { MenuItem, Select } from "@mui/material";
import { Input } from "antd";
import ImageDropzone from "../pages/ImageDropzone";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import AntDesignSideBar from "../components/AntDesignSideBar";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
const drawerWidth = 240;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f5f5f5",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
function SinglePropertyAnalysis(props) {
  const theme = useTheme();
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(fixForms);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FixForms");
    XLSX.writeFile(workbook, "fixForms.xlsx");
  };
  const handleExportRow = (rowData) => {
    const worksheet = XLSX.utils.json_to_sheet([rowData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FixForm");
    XLSX.writeFile(workbook, "fixForm.xlsx");
  };
  const CardStyle = styled(Card)(({ theme }) => ({
    background: "#498dd6",
    marginBottom: "22px",
    overflow: "hidden",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "157px",
      height: "157px",
      background: "#498dd6",
      borderRadius: "50%",
      top: "-105px",
      right: "-96px",
    },
  }));
  const params = useParams();
  const [fixForms, setFixForms] = useState([]);
  const navigate = useNavigate();
  const commonStyles = {
    bgcolor: "background.paper",
    m: 1,
  };
  useEffect(() => {
    axios
      .get("https://3.139.67.124:8080/api/singleProperty/getAllSingle") // Adjust the API endpoint
      .then((response) => {
        setFixForms(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <AntDesignSideBar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Typography variant="h4" style={{ color: "grey" }}>
            Single Property
          </Typography>
          <Button
            style={{
              backgroundColor: "#498dd6",
              color: "white",
              marginBottom: 10,
            }}
          >
            Export All
          </Button>
          <Divider />
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Borrower Cell</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fixForms.map((form, index) => (
                    <TableRow key={index}>
                      <TableCell>{form.borrowerCell}</TableCell>
                      <TableCell>{form.firstName}</TableCell>
                      <TableCell>{form.borrowerLast}</TableCell>
                      <TableCell>{form.borrowerEmail}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#498dd6" }}
                          onClick={() => handleExportRow(form)}
                        >
                          Export
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Box>
    </div>
  );
}

SinglePropertyAnalysis.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SinglePropertyAnalysis;
